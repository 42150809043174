import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Auth, getAuth, IdTokenResult, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { FirebaseContext, Props } from './FirebaseProvider';

export type Claims = 'kadampa-gpt-ask' | 'kadampa-gpt-configure-prompt';

export type AuthContext = {
  auth: Auth | null,
  authLoading: boolean,
  idTokenResult: IdTokenResult | null,
  idToken: string | null,
  hasClaim: null | ((claim: Claims) => boolean | undefined),
  user: User | null,
  logout: null | (() => void)
}

export const AuthContext = createContext<AuthContext>({
  auth: null,
  authLoading: true,
  idTokenResult: null,
  idToken: null,
  hasClaim: null,
  user: null,
  logout: null
});

export const AuthProvider = (props: Props) => {
  const { children } = props;
  const firebaseApp = useContext(FirebaseContext);
  const [authInitializing, setAuthInitializing] = useState(true);
  const [auth, setAuth] = useState<Auth | null>(null);
  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  const [idTokenResult, setIdTokenResult] = useState<IdTokenResult | null>(null);

  const logout = useCallback(() => {
    if (auth) {
      signOut(auth).then(() => {
        setIdToken(null);
        setIdTokenResult(null);
        setUser(null);
      }).catch((error) => {
        console.error('Sign out error', error);
      });
    }
  }, [auth]);

  useEffect(() => {
    if (firebaseApp !== null) {
      setAuth(getAuth());
      setAuthInitializing(false);
    }
  }, [firebaseApp]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      user?.getIdToken(true)
        .then(refreshedToken => {
          setIdToken(refreshedToken);
        });
      user?.getIdTokenResult(true).then(idTokenResult => {
        setIdTokenResult(idTokenResult);
      });
    }, (3600 * 1000) - (5 * 60 * 1000));

    return () => clearInterval(intervalId);
  }, [user]);

  useEffect(() => {
    if (auth !== null) {
      return onAuthStateChanged(auth, (user) => {
        setUser(user);
        auth.currentUser?.getIdToken(true).then(function(idToken) {
          setIdToken(idToken);
        });
        setAuthLoading(false);
        if (user !== null) {
          user.getIdTokenResult(true).then(idTokenResult => {
            setIdTokenResult(idTokenResult);
          });
        } else {
          setIdToken(null);
        }
      });
    }
  }, [auth]);

  const hasClaim = useCallback((claim: Claims) => {
    if (idTokenResult === null) return undefined;
    return (idTokenResult?.claims[claim] === true);
  }, [idTokenResult]);

  const providerValues = {
    auth,
    authLoading,
    hasClaim,
    idTokenResult,
    idToken,
    user,
    logout
  };

  if (authInitializing) {
    return <></>;
    // return <SuspenseFallback />;
  }

  return (
    <AuthContext.Provider value={providerValues}>{children}</AuthContext.Provider>
  );
};
