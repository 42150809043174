import { ReactNode } from 'react';

interface CentredProps {
  children: ReactNode;
  className?: string;
}

export const Centred = ({ children, className = '' }: CentredProps) => {
  return <div className={`max-w-screen-2xl mx-auto w-full flex transition-all md:px-4 ${className}`}>{children}</div>;
};
