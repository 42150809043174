import { useRef } from 'react';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import { useGSAP } from '@gsap/react';

gsap.registerPlugin(SplitText);

const Split = ({ children }: { children: any }) => {
  const questionTextRef = useRef<HTMLSpanElement>(null);

  useGSAP(() => {
    const split = new SplitText(questionTextRef.current, { type: 'words,chars' });
    gsap.from(split.chars, { scale: 0.1, autoAlpha: 0, stagger: 0.01 });
  }, []);

  return (
    <span ref={questionTextRef}>
      {children}
    </span>
  );
};

export default Split;
