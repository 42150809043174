import { Card, CardHeader } from '@/components/ui/card.tsx';
import { Centred } from '@/app/shared/Centred.tsx';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
import { useGSAP } from '@gsap/react';
import './Home.css';
import { useEffect, useRef, useState } from 'react';
import { homeCards } from '@/app/routes/home/home-cards.tsx';

const inactiveCardOpacity = 0.9;

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(Flip);

const cssVar = (varName: string) => getComputedStyle(document.documentElement).getPropertyValue(varName);

const Home = () => {
    const container = useRef<HTMLDivElement | null>(null);
    const [active, _setActive] = useState(0);
    const [oldActive, setOldActive] = useState<number | null>(null);
    const [heights, setHeights] = useState<number[]>([]);

    useEffect(() => {
      const cardContents = document.querySelectorAll('.card-content');
      const newHeights = Array.from(cardContents).map(content => content.scrollHeight);
      setHeights(newHeights);
    }, []);

    useGSAP(() => {
      if (oldActive !== null) {
        const duration = 0.7;

        const state = Flip.getState('.card, .card-content');
        const cards: HTMLElement[] = gsap.utils.toArray('.card');
        cards[oldActive].dataset.grid = cards[active].dataset.grid;
        cards[active].dataset.grid = 'img-0';

        cards[oldActive].style.zIndex = '1';
        cards[active].style.zIndex = '100';

        gsap.set('.card-content', {
          height: (i) => (i === active ? `${heights[i]}px` : '0')
        });

        Flip.from(state, {
          duration,
          absolute: true,
          nested: true,
          ease: 'power1.inOut'
        });

        gsap.to('.card', {
          backgroundColor: (i) => (i === active ? cssVar('--surface2') : cssVar('--surface1')),
          opacity: (i) => (i === active ? 1 : inactiveCardOpacity),
          duration,
          ease: 'power1.out'
        });

        gsap.to('.card-content', {
          opacity: (i) => (i === active ? 1 : 0),
          duration: duration,
          ease: 'power1.out',
          delay: 0.25
        });
      }
    }, [active]);

    useGSAP(() => {
      const tl = gsap.timeline();

      tl.set('.card', {
        backgroundColor: (i) => (i === active ? cssVar('--surface2') : cssVar('--surface1')),
        opacity: (i) => (i === active ? 1 : inactiveCardOpacity),
      });

      tl.set('.card-content', {
        opacity: (i) => (i === active ? 1 : 0),
        height: (i) => (i === active ? `${heights[i]}px` : '0')
      });

    }, []);

    const setActive = (num: number) => {
      setOldActive(active);
      _setActive(num);
    };

    return (
      <Centred>
        <div className="max-lg:cards-sm lg:cards overflow-visible w-full transition-all md:p-32" ref={container}>
          {homeCards.map((card, i) => (
            <Card className={`card w-full overflow-hidden ${i === active ? 'rad-shadow' : 'rad-shadow3'}`} data-grid={`img-${i}`} onClick={() => setActive(i)} key={i}>
              <CardHeader className="text-[var(--text1)]">
                {card.title}
                {card.description}
              </CardHeader>
              <div className="card-content">
                {card.content}</div>
            </Card>
          ))}
        </div>
      </Centred>
    );
  }
;

export default Home;
