import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client';

import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { AuthProvider } from './app/providers/AuthProvider';
import { FirebaseProvider } from './app/providers/FirebaseProvider';
import './index.css'

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <FirebaseProvider>
      <AuthProvider>
        {/*<PrimeReactProvider value={{ unstyled: false }}>*/}
          <RouterProvider router={router} />
        {/*</PrimeReactProvider>*/}
      </AuthProvider>
    </FirebaseProvider>
  </StrictMode>
)
