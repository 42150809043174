import { CardContent, CardDescription, CardTitle } from '@/components/ui/card.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import Split from '@/app/shared/Split.tsx';

export const homeCards = [
  {
    title: <CardTitle className="text4"><Split>Search</Split></CardTitle>,
    description: <CardDescription className="card-description text2"><Split>Find passages in the books of Geshe
      Kelsang Gyatso and other Kadampa teachings
    </Split></CardDescription>,
    content: <CardContent className="text-[var(--text1)] w-full">
      <div className="flex w-full items-center space-x-2">
        <Input type="email" placeholder="Enter a phrase or an idea to search for..." className={'surface3'} />
        <Button type="submit">Search</Button>
      </div>
    </CardContent>
  },
  {
    title: <CardTitle className="text5"><Split>Study</Split></CardTitle>,
    description: <CardDescription className="card-description text2"><Split>Memorise dharma text and test your knowledge and understanding</Split></CardDescription>,
    content: <CardContent className="text-[var(--text1)]">
      <em>Not quite ready</em>
    </CardContent>
  },
  {
    title: <CardTitle className="text6"><Split>Dharma Centres</Split></CardTitle>,
    description: <CardDescription className="card-description text2"><Split>
      Find out what's going on in Kadampa Centres and subscribe to notifications about events you might be interested in
    </Split></CardDescription>,
    content: <CardContent className="text-[var(--text1)]">
      <em>Not quite ready</em>
    </CardContent>
  },
  {
    title: <CardTitle className="text7"><Split>Kadampa GPT</Split></CardTitle>,
    description: <CardDescription className="card-description text2"><Split>Ask AI a question about Kadam
      Dharma</Split></CardDescription>,
    content: <CardContent className="text-[var(--text1)]">
      <em>Not quite ready</em>
    </CardContent>
  },
  {
    title: <CardTitle className="text8"><Split>Tools</Split></CardTitle>,
    description: <CardDescription className="card-description text2"><Split>Transcribe teachings from audio and video</Split></CardDescription>,
    content: <CardContent className="text-[var(--text1)]">
      <em>Not quite ready</em>
    </CardContent>
  }
];
