import { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Home from '@/app/routes/home/Home.tsx';
import PdfReader from '@/app/routes/books/PdfReader.tsx';

const App = lazy(() => import('./app/App'));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<App />}
    >
      <Route
        element={<Home />}
        index={true}
      />
      <Route
        element={<PdfReader />}
        path="/books"
      />
    </Route>
  )
);
