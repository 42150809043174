import { Document, Page, pdfjs } from 'react-pdf';
import { useEffect, useRef, useState } from 'react';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfReader = () => {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber] = useState<number>(12); // Removed setPageNumber
  const [highlight, setHighlight] = useState<{ left: number; top: number; width: number; height: number } | null>(null);
  const canvasRef = useRef<HTMLDivElement>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  // Function to access named destination and highlight it if it matches the page number
  async function extractNamedDestinationAndHighlight(pdf: pdfjs.PDFDocumentProxy, pageNum: number) {
    try {
      // Get the page and its reference number
      const page = await pdf.getPage(pageNum);
      const pageRef = page.ref;
      const objectNumber = pageRef?.num;

      // Get all named destinations
      const destinations = await pdf.getDestinations();

      console.log('Destinations:', destinations);
      return;

      // Check if 'my-id' exists in the destinations
      const destination = destinations['my-id'];

      console.log({ destination, objectNumber });
      if (destination) {
        const destinationPageRef = destination[0];
        if (destinationPageRef.num === objectNumber) {
          // If the page reference matches, extract coordinates and set highlight
          const [, , left, top, right, bottom] = destination;

          // Adjust the y-coordinate since PDF origin is at the bottom-left
          const viewport = page.getViewport({ scale: 1 });
          const adjustedTop = viewport.height - top;
          const adjustedBottom = viewport.height - bottom;

          // Create a rectangle representing the highlight area
          setHighlight({
            left: left,
            top: adjustedTop,
            width: right - left,
            height: adjustedBottom - adjustedTop
          });
        } else {
          setHighlight(null); // No match, clear highlight
        }
      } else {
        setHighlight(null); // No destination found, clear highlight
      }
    } catch (error) {
      console.error('Error retrieving data:', error);
      setHighlight(null);
    }
  }

  useEffect(() => {
    const loadPDFAndSearch = async (filename: string) => {
      const loadingTask = pdfjs.getDocument(filename);
      const pdf = await loadingTask.promise;
      extractNamedDestinationAndHighlight(pdf, 1);
    };

    for (let i = 1; i < 184; i++) {
      loadPDFAndSearch(`toiom_page_${i.toString().padStart(4, '0')}.pdf`);
    }

  }, [pageNumber]);

  console.log({ highlight });
  return (
    <div>
      <Document file="book.pdf" onLoadSuccess={onDocumentLoadSuccess}>
        <div style={{ position: 'relative' }} ref={canvasRef}>
          <Page pageNumber={pageNumber} />
          {highlight && (
            <div
              id={'highlight'}
              style={{
                position: 'absolute',
                left: `${highlight.left}px`,
                top: `${highlight.top}px`,
                width: `${highlight.width}px`,
                height: `${highlight.height}px`,
                backgroundColor: 'rgba(255, 255, 0, 0.5)', // Yellow highlight with some transparency
                pointerEvents: 'none'
              }}
            />
          )}
        </div>
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p>
    </div>
  );
};

export default PdfReader;
