import { createContext, ReactNode, useEffect, useState } from 'react';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { Analytics } from '@firebase/analytics';
import { firebaseConfig } from '../../firebaseConfig';

export type FirebaseContext = { app: FirebaseApp | null };
export type FirebaseAnalyticsContext = { analytics: Analytics | null };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const FirebaseContext = createContext<FirebaseContext>({ app: null });

export type Props = {
  children: ReactNode;
}

export const FirebaseProvider = (props: Props) => {
  const { children } = props;

  const [firebaseInitializing, setFirebaseInitializing] = useState(true);
  const [firebaseApp, setFirebaseApp] = useState<FirebaseContext>({ app: null });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [analytics, setAnalytics] = useState<FirebaseAnalyticsContext>({ analytics: null });

  useEffect(() => {
    setFirebaseApp({ app: initializeApp(firebaseConfig) });
    setFirebaseInitializing(false);
  }, []);

  useEffect(() => {
    if (firebaseApp.app !== null) {
      const analytics = getAnalytics(firebaseApp.app);
      setAnalytics({ analytics });
    }
  }, [firebaseApp]);

  if (firebaseInitializing) {
    return <div></div>;
    // return <SuspenseFallback />;
  }

  return (
    <FirebaseContext.Provider value={firebaseApp}>
      {children}
    </FirebaseContext.Provider>
  );
};
